import React from 'react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Social from './Social';

export default function MainArea() {
  return (
    <div className='max-w-full w-[400px] sm:w-full mx-auto'>
      <div className='w-full rounded-2xl overflow-x-clip relative md:block hidden' style={{ background: "linear-gradient(72deg, #313130 0%, #3c3c3a 50%)" }}>
          <Swiper
                spaceBetween={50}
                slidesPerView={1}
              modules={[Autoplay]} 
                autoplay={{
                    delay: 6000,  
                    disableOnInteraction: false,  
                    }}   
                >
              <SwiperSlide className='z-50'>
                  <div className='min-h-[400px] md:min-h-[600px] lg:min-h-[calc(100vh-120px)]'>
                    <a className='bottom-3 sm:bottom-8 right-3 sm:right-8 absolute z-50' target='_blank' href="https://www.eventbrite.com/e/100-ways-to-generate-and-earn-income-on-the-blockchain-in-2025-tickets-1097509764249?aff=oddtdtcreator">
                          <img className="h-6 sm:h-10 lg:h-16 object-contain" src={require('../assets/bottom-logo.png')} alt='bottom-logo.png' />
                      </a> 
                
                      <img src={require('../assets/bg-mobile.png')} className='sm:hidden max-h-[600px]  select-none sm:absolute inset-0 w-full sm:size-full object-right-bottom object-cover' alt="Flowers"  />
                      <img src={require('../assets/bg.png')} className='hidden sm:block select-none sm:absolute inset-0 w-full sm:size-full object-right-bottom object-contain sm:object-cover' alt="Flowers"  />
                   
                  </div>
                  
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='h-[400px] md:min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-1.png')} alt='section-slide-1.png' />
                </div>
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='h-[400px] md:min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-2.png')} alt='section-slide-2.png' />
                </div>
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='h-[400px] md:min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-3.png')} alt='section-slide-3.png' />
                </div>
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='h-[400px] md:min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-4.png')} alt='section-slide-4.png' />
                </div>
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='h-[400px] md:min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-5.png')} alt='section-slide-5.png' />
                </div>
              </SwiperSlide>  
              <SwiperSlide className=''>
                  <div className='h-[400px] md:min-h-[calc(100vh-120px)]'>
                    <img className="size-full inset-0 absolute object-cover" src={require('../assets/section-slide-6.png')} alt='section-slide-6.png' />
                </div>
              </SwiperSlide>  
            </Swiper>
           
      </div>

      <div className='w-full rounded-2xl overflow-x-clip relative md:hidden' style={{ background: "linear-gradient(72deg, #313130 0%, #3c3c3a 50%)" }}>
        
              
                  
      <div className='min-h-[400px] md:min-h-[600px] lg:min-h-[calc(100vh-120px)]'>
                    <a className='bottom-3 sm:bottom-8 right-3 sm:right-8 absolute z-50' target='_blank' href="https://www.eventbrite.com/e/100-ways-to-generate-and-earn-income-on-the-blockchain-in-2025-tickets-1097509764249?aff=oddtdtcreator">
                          <img className="h-6 sm:h-10 lg:h-16 object-contain" src={require('../assets/bottom-logo.png')} alt='bottom-logo.png' />
                      </a> 
                
                      <img src={require('../assets/bg-mobile.png')} className='sm:hidden max-h-[600px]  select-none sm:absolute inset-0 w-full sm:size-full object-right-bottom object-cover' alt="Flowers"  />
                      <img src={require('../assets/bg.png')} className='hidden sm:block select-none sm:absolute inset-0 w-full sm:size-full object-right-bottom object-contain sm:object-cover' alt="Flowers"  />
                   
                  </div>
      </div>
      
      <span className='flex md:hidden pt-3'>
                <Social />
      </span>
    </div>
  )
}
