import React from 'react'
import { CgMenuLeft } from 'react-icons/cg'
import { Link } from 'react-router-dom'
import Social from './Social'

function Navbar({ setDrawer }) {
  return (
    <div className='shrink-0 flex bg-[#191919] rounded-full w-full md:p-3 justify-between items-center gap-8 p-1.5 px-2'>
      <CgMenuLeft onClick={() => setDrawer(true)} className='text-[#b59004] cursor-pointer xl:hidden flex text-2xl' />
      <span className='lg:flex hidden'>
        <Social />
      </span>
      <h1 className='  text-[#b59004]  md:text-3xl text-sm'>Cryptopaywelldaily.com</h1>
      <Link to='/contact-us' className='md:px-6 md:py-3 px-3 py-1.5 text-sm md:text-base text-white whitespace-nowrap bg-[#b59004] rounded-3xl border-[#b59004] border-[1px] hover:bg-transparent transition-all ease-in duration-150'>
        Contact Us
      </Link>
    </div>
  )
}

export default Navbar
