import React from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
function SideBar({ setNewsLetter, setDrawer }) {
    return (
        <div className='w-full xl:h-full bg-[#191919] xl:rounded-2xl flex flex-col px-4 py-4 gap-2 relative  h-screen overflow-y-auto  '>
            <button type="button" onClick={()=>setDrawer(false)} className='absolute right-3 top-3 text-white size-7 flex justify-center items-center rounded-full bg-[#B59004] xl:hidden'>x</button>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://chatgpt.com/g/g-6735356888188190964e18e67957ab11-platinumo2-assistant'} className='text-center w-full pt-2 pb-4 text-[24px] text-[#B59004] font-bold'>
            ChatGPT Page
            </a>
            <Link onClick={()=>setDrawer(false)} to={'/about-m2-20'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Group 5.png')} className='md:w-7 w-6' />
                About The M2-20
            </Link>
            <Link onClick={()=>setDrawer(false)} to={'/watch-video'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Mask group.png')} className='md:w-7 w-6' />
                View Video
            </Link> 
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://drive.google.com/file/d/1dvwrAYUxjh0U0AUYac0moL0VMCDLWsxM/view?usp=drive_link'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Mask group1.png')} className='md:w-7 w-6' />
                Download Brochure
            </a>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://calendly.com/m2-20/free-consultation'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Mask group2.png')} className='md:w-7 w-6' />
                Book Your Consultation
            </a>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://buy.stripe.com/4gw6sk6426kR2bufYY'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Mask group4.png')} className='md:w-7 w-6' />
                Buy Now
            </a>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://nowpayments.io/payment/?iid=5956942317'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/Mask group8.png')} className='md:w-7 w-6' />
                Buy With Crypto
            </a>
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://platinumo2.com/'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/platinumo2 logo 1.png')} className='md:w-7 w-6' />
                <span>Platinum <span>O<sup>2</sup></span>.com</span>
            </a>
            <Link onClick={()=>setDrawer(false)} to={'/opportunities'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
            <img src={require('../assets/sidebar-icon-6.png')} className='md:w-7 w-6' />
                <span>$$ Opportunities</span>
            </Link>  
            <a onClick={()=>setDrawer(false)} target='_blank' href={'https://www.tumblr.com/platinumo2-blog/769098226428215296/100-ways-to-generate-and-earn-income-on-the?source=share'} className='flex justify-start items-center gap-2 bg-[#1f1f1f] rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white shadow-sm shadow-[#1f1f1f]'>
                <img src={require('../assets/sidebar-icon-7.png')} className='md:w-7 w-6' />
                <span>Blog Page</span>
            </a>

            <div className='pt-7'>
                <Swiper
                spaceBetween={50}
                slidesPerView={1}
                modules={[Autoplay]}
                autoplay={{
                    delay: 3000,  
                    disableOnInteraction: false,  
                    }}  
                    
                >
                    <SwiperSlide className='!flex justify-center'> <img className='h-48 object-contain' src={require('../assets/slider-img-1.png')} /></SwiperSlide> 
                    <SwiperSlide className='!flex justify-center'> <img className='h-48 object-contain' src={require('../assets/slider-img-2.png')} /></SwiperSlide> 
                    <SwiperSlide className='!flex justify-center'> <img className='h-48 object-contain' src={require('../assets/slider-img-3.png')} /></SwiperSlide> 
                </Swiper>
            </div>

          
            <div onClick={() => {setDrawer(false); setNewsLetter(true); }} className='cursor-pointer md:mt-0  mt-5 flex md:justify-center items-center gap-2 rounded-xl px-3 py-2.5 text-sm hover:shadow-none text-white md:absolute md:bottom-2 left-[35%]'>
                <img src={require('../assets/Group 6.png')} className='md:w-7 w-6' />
                Sign up
            </div>
        </div>
    )
}

export default SideBar
